import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Segment, Statistic } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import QRCode from 'qrcode.react';

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('alipay');
  const [amountLevel, setAmountLevel] = useState('');
  const [customAmount, setCustomAmount] = useState(0);
  const [amountLevels, setAmountLevels] = useState([]);
  const [qrCode, setQRCode] = useState('');

  const topUp = async () => {
    if (redemptionCode === '') {
      showInfo('请输入充值码！')
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode
      });
      const { success, message, data } = res.data;
      if (success) {
        showSuccess('充值成功！');
        setUserQuota((quota) => {
          return quota + data;
        });
        setRedemptionCode('');
      } else {
        showError(message);
      }
    } catch (err) {
      showError('请求失败');
    } finally {
      setIsSubmitting(false); 
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError('超级管理员未设置充值链接！');
      return;
    }
    window.open(topUpLink, '_blank');
  };

  const getUserQuota = async ()=>{
    let res  = await API.get(`/api/user/self`);
    const {success, message, data} = res.data;
    if (success) {
      setUserQuota(data.quota);
    } else {
      showError(message);
    }
  }

  const handleDirectTopUp = async () => {
    // 检查参数是否完整
    if (!paymentMethod) {
      showError('请选择支付方式！');
      return;
    }
    if (!amountLevel) {
      showError('请选择金额等级！');
      return;
    }
    let amount = amountLevel === 'custom' ? customAmount : amountLevels.find(level => level.id === amountLevel).amount;
    if (!amount) {
      showError('请输入充值金额！');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await API.post('/api/user/order/create', {
        device_id: 'web',
        amount: parseFloat(amountLevel === 'custom' ? customAmount : amountLevels.find(level => level.id === amountLevel).amount),
        pay_method: paymentMethod
      });

      const { code, data } = response.data;
      if (code === 100) {
        if (data.link) {
          // window.open(data.link, '_blank');
          window.open(data.link, '_self');
          // 当前页面跳转
          // window.href.location = data.link;
        } else if (data.qrcode) {
          setQRCode(data.qrcode);
        }
        showSuccess('订单创建成功！');
      } else {
        showError('订单创建失败：' + response.data.message);
      }
    } catch (err) {
      showError('请求失败：' + err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
    getUserQuota().then();

    const fetchAmountLevels = async () => {
      try {
        const res = await API.get('/api/user/money-token-relation');
        if (res.data && typeof res.data === 'object') {
          const levels = Object.entries(res.data.data).map(([key, value]) => ({ id: key, amount: key, token: value }));
          setAmountLevels(levels);
        } else {
          showError('后端返回的数据不是一个对象');
        }
      } catch (err) {
        showError('获取金额等级失败');
      }
    };

    fetchAmountLevels().then();
  }, []);

  return (
    <Segment>
      <Header as='h3'>充值额度</Header>
      <Grid columns={2} stackable>
        <Grid.Column>
          <Form>
            <Form.Input
              placeholder='兑换码'
              name='redemptionCode'
              value={redemptionCode}
              onChange={(e) => {
                setRedemptionCode(e.target.value);
              }}
            />
            <Button color='green' onClick={openTopUpLink}>
              获取兑换码
            </Button>
            <Button color='yellow' onClick={topUp} disabled={isSubmitting}>
              {isSubmitting ? '兑换中...' : '兑换'}
            </Button>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Statistic.Group widths='one'>
            <Statistic>
              <Statistic.Value>{renderQuota(userQuota)}</Statistic.Value>
              <Statistic.Label>剩余额度</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Grid.Column>
      </Grid>
      <Segment>
        <Header as='h3'>直接充值</Header>
        <Form>
          <Form.Select
            label='支付方式'
            options={[
              { key: 'alipay', text: '支付宝', value: 'alipay' },
              { key: 'wechat', text: '微信扫码支付', value: 'wechat' },
              { key: 'wechat_h5', text: '微信唤起支付(请在手机的默认浏览器使用)', value: 'wechat_h5' },
              { key: 'qqpay', text: 'QQ扫码支付', value: 'qqpay' },
              { key: 'paypal', text: 'Paypal', value: 'paypal' },
            ]}
            value={paymentMethod}
            onChange={(e, { value }) => setPaymentMethod(value)}
          />
          <Form.Select
            label='金额等级'
            options={amountLevels.map(level => ({
              key: level.id,
              text: level.amount === "custom"? "每 1 元 购买 " + level.token + " Tokens，选我自定义金额" : level.amount + "元 购买 " + level.token + " Tokens",
              value: level.id,
            }))}
            value={amountLevel}
            onChange={(e, { value }) => setAmountLevel(value)}
          />
          {amountLevel === 'custom' && (
            <Form.Input
              label='自定义金额（元）'
              type='number'
              value={customAmount}
              onChange={(e) => setCustomAmount(e.target.value)}
            />
          )}
          <Button color='blue' onClick={handleDirectTopUp} disabled={isSubmitting}>
            充值
          </Button>
          {qrCode && (
            <div>
              <h3>请扫描以下二维码完成支付，支付完成请刷新页面：</h3>
              <QRCode value={qrCode} />
            </div>
          )}
        </Form>
      </Segment>
    </Segment>
  );
};

export default TopUp;